'use client';

import { useEffect, useState } from 'react';

export default function AriaLive() {
  const [content, setContent] = useState<string>();

  useEffect(() => {
    document.addEventListener('__LokappyAriaLive__', handleAriaLive);

    return () => {
      document.removeEventListener('__LokappyAriaLive__', handleAriaLive);
    };
  }, []);

  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  const handleAriaLive = ({ detail: { content } }: any) => {
    setContent(content as string);
  };

  return (
    <div
      className="-m-[0.0625rem] invisible absolute row-start-3 h-[0.0625rem] w-[0.0625rem] overflow-hidden border-0 p-0"
      aria-live="polite"
      aria-atomic="true"
    >
      {(content?.trim() ?? '') !== '' ? content : null}
    </div>
  );
}
